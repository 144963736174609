import { getField, updateField } from 'vuex-map-fields'
import { defaultSerie } from '@/utils/workouts'

const initialState = () => ({
  workout: {
    name: '',
    description: '',
    mediaUrl: '',
    day: '',
    rpe: false,
    exercises: []
  }
})

export const state = () => initialState()

export const getters = {
  getField,
  workout(state) {
    return state.workout
  }
}

export const actions = {
  updateExerciseField({ commit }, { index, field, value }) {
    commit('updateField', {
      path: `workout.exercises[${index}].${field}`,
      value
    })
  },
  deleteExercise({ commit, state }, { index }) {
    commit('deleteExercise', { index })
    // commit('deleteExercise', {
    //   path: 'workout.exercises',
    //   value: state.workout.exercises.filter((exercise, i) => i !== index)
    // })
  },
  updateSerie({ commit }, { indexExercise, indexSerie, value }) {
    commit('updateSerie', {
      indexExercise,
      indexSerie,
      value
    })
  },
  addSerieField({ commit, state }, { index }) {
    const path = `workout.exercises[${index}].series`
    const series = state.workout.exercises[index].series || []
    commit('updateField', {
      path,
      value: [
        ...series,
        {
          ...defaultSerie
        }
      ]
    })
  },
  duplicateSerieField({ commit, state }, { indexExercise, indexSerie }) {
    const path = `workout.exercises[${indexExercise}].series`
    const series = state.workout.exercises[indexExercise].series || []
    const serie = series[indexSerie]
    commit('updateField', {
      path,
      value: [
        ...series,
        {
          ...serie,
          id: null
        }
      ]
    })
  },
  updateSerieField({ commit }, { indexExercise, indexSerie, value, field }) {
    commit('updateField', {
      path: `workout.exercises[${indexExercise}].series[${indexSerie}].${field}`,
      value
    })
  },
  deleteSerieField({ commit, state }, { indexExercise, indexSerie }) {
    console.log('deleteSerieField', indexExercise, indexSerie)
    commit('deleteSerie', {
      indexExercise,
      indexSerie
    })
  },
  updateWorkout({ commit }, payload) {
    console.log('updateWorkoutPayload', payload)
    commit('setWorkout', payload)
  },
  clearWorkout({ commit }) {
    commit('setWorkout', initialState().workout)
  }
}

export const mutations = {
  updateField,
  setWorkout(state, payload) {
    state.workout = payload
  },
  updateSerie(state, { indexExercise, indexSerie, value }) {
    console.log('updateSerie', indexExercise, indexSerie, value)
    const serieToUpdate =
      state.workout.exercises[indexExercise].series[indexSerie]

    console.log('serieToUpdate', serieToUpdate)
    state.workout.exercises[indexExercise].series.splice(indexSerie, 1, {
      ...serieToUpdate,
      ...value
    })

    console.log(
      'state.workout.exercises[indexExercise].series',
      state.workout.exercises[indexExercise].series
    )
  },
  deleteSerie(state, { indexExercise, indexSerie }) {
    const value = state.workout.exercises[indexExercise].series[indexSerie]
    console.log('deleteSerie', indexExercise, indexSerie, value)

    if (!value.id) {
      // Se a série ainda for salva, só deleta
      state.workout.exercises[indexExercise].series.splice(indexSerie, 1)
      return
    }

    const newValue = {
      ...value,
      deleted: true
    }

    state.workout.exercises[indexExercise].series.splice(
      indexSerie,
      1,
      newValue
    )
  },
  deleteExercise(state, { index }) {
    const value = state.workout.exercises[index]
    console.log('deleteExercise', index, value)

    if (!value.id) {
      // Se o exercício ainda for salvo, só deleta
      state.workout.exercises.splice(index, 1)
      return
    }

    const exerciseHasSeries = value.series && value.series.length > 0
    if (exerciseHasSeries) {
      value.series.forEach((serie, indexSerie) => {
        const newValue = {
          ...serie,
          deleted: true
        }

        state.workout.exercises[index].series.splice(indexSerie, 1, newValue)
      })
    }

    const newValue = {
      ...value,
      deleted: true
    }

    state.workout.exercises.splice(index, 1, newValue)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
